import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import Information from './../../../../Shared/Card/Information';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../Shared/Card/Issues';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import reinstatementService from '../../../../../services/Internal/Reinstatement/EI/eiReinstatement.service';
import violationService from '../../../../../services/Internal/Reinstatement/EI/violation.service';
import Swal from 'sweetalert2';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { getReinstatementCodeViolationAnswers } from '../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../Shared/Card/Violations';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const sectionId = 3;
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        signature: StringType().isRequired('This field is required.'),
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Violations"
        }
    );
    useEffect(() => {
        setHasLoaded(false);
        reinstatementService.eiGetSection(store.currentApplicationId, sectionId).then((response) => {
            violationService.getViolation(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, issues: formData.issues, files: formData.files, filesForDisplay: formData.filesForDisplay, signature: response.data.electronicSignature });
                }
                else {
                    getReinstatementCodeViolationAnswers(5).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete)
                reinstatementService.getIndividualReinstatement(store.currentApplicationId).then((reinstatementData) => {
                    setIsUserEditable(reinstatementData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: reinstatementData.data?.credential?.formattedLicenseNumber,
                                applicationDate: reinstatementData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        signature: undefined,
        yesAnswerExplanation: "",
        files: [],
        filesForDisplay: [],
        issues: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true && x.codeViolationQuestion.versionNumber < 3) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);

    //Verison 3+ violations
    const showBlukViolationExplanation = formData?.answers?.findIndex(x => x.answer == true && !x.codeViolationQuestion.hasExplanation && x.codeViolationQuestion.versionNumber >= 3) >= 0 ?
        `If the answer to question number 2-${formData.answers[0].codeViolationQuestion.versionNumber == 3 ? '6' : '5'} is "Yes", provide the date and the pertinent facts of the case or proceeding including its final disposition. Upload additional pages if necessary` : "";

    const downloadViolations = () => {
        violationService.downloadViolationFile(store.currentApplicationId)
    }
    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            setShowFormErrors(true)
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }
                if (validationObject[key].hasError && (showBlukViolationExplanation && key.includes("yesAnswerExplanation"))) {
                    return;
                }
            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (formData?.answers?.findIndex(x => x.answer === true && x.codeViolationQuestion.hasExplanation && !x.violationExplanation) >= 0) {//undefined check
                return;
            }
            if (formData.signature) {
                if (showAdditionalFields === false || val) {
                    var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.currentApplicationEntityId };
                    violationService.postViolation(dataTransfer).then((response) => {
                        reinstatementService.eiMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                            reinstatementService.eiPostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                                if (response.data && data.data) {
                                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                                }
                            })
                        })
                    });
                }
            }
        }

    }
    const formSave = () => {
        var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.currentApplicationEntityId };
        violationService.postViolation(dataTransfer).then((response) => {
            updateFormData({ ...response.data, signature: formData.signature });
            reinstatementService.eiMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                reinstatementService.eiPostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                    Swal.fire("Saved!")
                })
            })
        });
    }

    return (
        <div className="eiReinstatementViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col><Information content={GetReinstatementInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <GenericViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        showBlukViolationExplanation={showBlukViolationExplanation}
                                        downloadViolations={downloadViolations}
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        entityId={entityInfo.entityId}
                                        parentTypeId={2}
                                        credentialTypeId={3}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                handleSave={formSave}
                                showSectionComplete={true}
                                backToSectionLink="/internal/eiReinstatement/sections"
                            />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;