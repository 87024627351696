import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, Schema, SelectPicker } from 'rsuite';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import { getCredStatus, getCredStatusReason } from '../../../../services/Shared/codeTable.service';
import RedStar from '../../../Shared/GenericComponents/RedStar';

function LegacyWgCredentialForm(props) {
    const [formData, setFormData] = useState({
        entityId: props.entityId,
        status: "",
        statusReason: "",
        firstIssuanceDate: null,
        effectiveDate: null,
        expirationDate: null,
        applicationDate: null,
        applicationType: "Waiver Granted",
        reinstatementDate: null,
        approvedBy: "",
        userMod: "",
        dateMod: null
    });
    const [credStatus, setCredStatus] = useState([])
    const [credReason, setCredReason] = useState([])
    useEffect(() => {
        getCredStatus().then((credentialStatuses) => {
            // 6 = Disapproved
            // 7 = Do Not Issue License
            // 9 = Lapsed Application
            var wgOnlyStatuses = credentialStatuses.filter((credentialStatus) => [6, 7, 9].includes(credentialStatus?.value?.id));
            setCredStatus(wgOnlyStatuses);
        });
    }, [])

    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        statusReason: ObjectType().isRequired('This field is required.'),
        status: ObjectType().isRequired('This field is required.'),

    })

    const handleCredentialStatusChange = (val) => {
        setFormData({ ...formData, status: val, statusReason: null })
        getCredStatusReason(val?.id).then(setCredReason);
    }

    const submitForm = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...formData
            }
            credentialService.createLegacyWgCredential(dataToTransmit).then((response) => {
                props.saveSuccess(response.data);
            });
        }
    }

    return (
        <Form id={props.formId} fluid model={model} formValue={formData} onChange={setFormData} onSubmit={submitForm} >
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Credential Status</b> <RedStar /></ControlLabel>
                        <FormControl
                            name="status"
                            block
                            data={credStatus}
                            accepter={SelectPicker}
                            searchable={false}
                            placeholder="Select Status"
                            onChange={handleCredentialStatusChange}
                        />
                </Col>
                <Col>
                    <ControlLabel><b>Credential Reason</b> <RedStar /></ControlLabel>
                    <FormControl
                            name="statusReason"
                            block
                            data={credReason}
                            accepter={SelectPicker}
                            searchable={false}
                            readOnly={!formData.status}
                            placeholder="Select Status Reason"
                        />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>First Issuance Date</b></ControlLabel>
                    <FormControl
                        name="firstIssuanceDate"
                        type="date"
                    />
                </Col>

                <Col>
                    <ControlLabel><b>Effective Date</b></ControlLabel>
                    <FormControl
                        name="effectiveDate"
                        type="date"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Application Date</b></ControlLabel>
                    <FormControl
                        name="applicationDate"
                        type="date"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Application Type</b></ControlLabel>
                    <FormControl
                        name="applicationType"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Licensed By</b></ControlLabel>
                    <FormControl
                        name="approvedBy"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Date of Last Activity</b></ControlLabel>
                    <FormControl
                        name="dateMod"
                        type="date"
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default LegacyWgCredentialForm;