import * as dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Icon, Table } from 'rsuite'
import Swal from 'sweetalert2';
import examTabService from '../../../../../services/Internal/Credential/examTab.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { sort_by } from '../../../../Shared/HelperFunctions';
import ExamsTabModal from './ExamsTabModal';

function ExamsPage({ entityId, activeKey }) {
    const [data, setData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});

    const [sortColumn, setSortColumn] = useState("examDate");
    const [sortType, setSortType] = useState("desc");

    useEffect(() => {
        if (activeKey == 'exams') {
            getData();
        }
    }, [activeKey])
    const getData = () => {
        examTabService.getExams(entityId).then((response) => {
            setData(response.data);
        })
        setHasLoaded(true);
    }
    const openModal = (rowData) => {
        if (rowData?.id) {
            setModalData({
                ...rowData,
                examDate: rowData.examDate ? dayjs(rowData.examDate).format('YYYY-MM-DD') : '',
                modalType: "Edit"
            });
        }
        else {
            setModalData({
                dateCrte: new Date(),
                deleted: false,
                entityId: entityId,
                examDate: '',
                id: '',
                comments: '',
                examDiscipline: null,
                status: null,
                userCrte: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }
    const deleteRow = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                examTabService.deleteExam(rowData.id).then((response) => {
                    if (response.data) {
                        Swal.fire("Success!", "You have successfully deleted an exam record.", "success");
                        getData();
                    }
                })
            }
        });
    }

    const getGridData = () => {
        var sortedData = [];
        if (sortColumn == "status") {
            sortedData = data?.sort(sort_by("status", sortType !== "asc", null, "status"));
        }
        else if (sortColumn == "examDiscipline") {
            sortedData = data?.sort(sort_by("examDiscipline", sortType !== "asc", null, "examDiscipline"));
        }
        else
            sortedData = data?.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData;
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    return (
        <>
            <ExamsTabModal updateGrid={() => { getData(); }} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
            {hasLoaded ? <>
                <Table
                    onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    wordWrap
                    autoHeight
                    data={getGridData()}>

                    <Table.Column minWidth={100} flexGrow={.5} sortable>
                        <Table.HeaderCell>
                            <b>Exam Date</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="examDate">{rowData => (rowData.examDate ? new Date(rowData.examDate).toLocaleDateString() : '')}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={200} flexGrow={1.5} sortable>
                        <Table.HeaderCell>
                            <b>Exam</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="examDiscipline">{rowData => (rowData.examDiscipline?.examDiscipline)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={.75} sortable>
                        <Table.HeaderCell>
                            <b>Status</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="status">{rowData => (rowData.status?.status)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1.5} sortable>
                        <Table.HeaderCell>
                            <b>Comments</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="comments">{rowData => (rowData.comments)}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={150}>
                        <Table.HeaderCell>
                            <b>Action(s)</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="id">
                            {rowData => {
                                function handleAction() {
                                    var tempData = { ...rowData, modalType: 'Edit' }
                                    openModal(tempData);
                                }
                                return (
                                    <>
                                        <Icon icon="edit2" size="2x" title="Edit" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                        <Icon icon="trash" size="2x" title="Delete" onClick={() => { deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                    </>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
                <br />
                <Row className="float-right">
                    <Button onClick={() => { openModal(); }}>Add Exam</Button>
                </Row>
            </> : <LoadingSpinner />
            }
        </>
    );
}
export default ExamsPage;