import React from 'react';
import { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Form, Table, FormControl, InputPicker, Uploader } from 'rsuite';
import Swal from 'sweetalert2';
import * as FaIcons from 'react-icons/fa';
import attachmentService from '../../../../services/Shared/attachment.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { sort_by } from '../../../Shared/HelperFunctions';

function DocumentUploadsCard(props) {
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [attachmentType, setAttachmentType] = useState();
    const [hasLoaded, setHasLoaded] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [attachmentTypeDropdown, setAttachmentTypeDropdown] = useState();
    const [sortColumn, setSortColumn] = useState("timestamp");
    const [sortType, setSortType] = useState("desc");

    const attachmentTypeChange = (e) => {
        setAttachmentType(e);
        setAttachmentTypeDropdown(e);
    }
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    const getData = () => {
        var sortedData = formData.filesForDisplay?.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData;
    }
    useEffect(() => {
        attachmentService.getAllDocTypes(props.isIndividual).then((data) => {
            setAttachmentTypes(data);
        });
    }, [])

    useEffect(() => { refreshGrid(); }
        , [attachmentTypes])

    useEffect(() => { refreshGrid(); }
        , [props.refreshData])

    const [formData, updateFormData] = useState({
        files: [],
        filesForDisplay: [],
        loading: false,
    });

    const refreshGrid = () => {
        setHasLoaded(false)
        updateFormData({
            ...formData,
            loading: true,
        })

        attachmentService.getFiles(props.entityId, 10).then((data) => {

            var attachmentTypeIds = attachmentTypes.map((attachmentType, index) => {
                return attachmentType.value;
            })
            var tempData = [];
            tempData = data?.data.filter(tempData => attachmentTypeIds.includes(tempData.documentTypeId)).map((tempData, index) => {
                console.log(tempData)
                return {
                    id: tempData.id,
                    timestamp: tempData.uploadDate,
                    file: tempData.fileName,
                    attachmentType: tempData.documentType,
                    fileLink: ''
                };
            })
            updateFormData({
                filesForDisplay: tempData,
                files: [],
                loading: false,
            })
            setHasLoaded(true)
        })
    }

    const deleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                attachmentService.deleteFile(id).then((response) => {
                    refreshGrid();
                })
            }
        })
    }

    const handleChange = (val, e) => {
        setIsUploading(true);
        if (!e) {
            updateFormData({
                ...formData,
                loading: true,
            })
            attachmentService.postFile(val, 10, props.entityId, attachmentType, props.entityId).then((response) => {
                setIsUploading(false);
                refreshGrid();
                if (attachmentTypes.length > 1) { // Only clear attachment type when there is a dropdown list of attachments on post
                    setAttachmentType("");
                    setAttachmentTypeDropdown("");
                }
            })
        }
    };

    return (
        <Card>
            <Card.Header>
                <h6>Document Uploads</h6>
            </Card.Header><Card.Body>
                <Form>

                    <Row>

                        <Col sm="12">
                            <strong>File Upload&nbsp;</strong>
                        </Col>

                    </Row>
                    {attachmentTypes.length > 1 &&
                        <Row className="mt-2">
                            <Col sm={props.isIndividual !== undefined ? "12" : "4"}>
                                <FormControl
                                    accepter={InputPicker}
                                    placeholder="Please Select"
                                    block
                                    data={attachmentTypes}
                                    name="attachmentType"
                                    onChange={attachmentTypeChange}
                                    value={attachmentTypeDropdown}
                                />
                            </Col>
                        </Row>
                    }
                    {!isUploading ?
                        <Row className="mt-2">
                            <Col sm="12">
                                <FormControl
                                    name="files"
                                    accepter={Uploader}
                                    listType="text"
                                    onUpload={handleChange}
                                    disabled={(!attachmentType)}
                                    fileListVisible={false}
                                    multiple
                                    draggable
                                    accept={attachmentService.ATTACHMENTTYPES}><div style={{ lineHeight: '200px' }}>Click or Drag files to this area to upload</div>
                                </FormControl>
                            </Col>
                        </Row>
                        :
                        <LoadingSpinner />
                    }
                    <Row>
                        <Col sm="12">
                            <br />
                            {hasLoaded ?

                                <Table
                                    onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                                    sortColumn={sortColumn}
                                    sortType={sortType}
                                    autoHeight
                                    wordWrap
                                    data={getData()}>

                                    <Table.Column flexGrow={2} minWidth={125} sortable>
                                        <Table.HeaderCell><b>File Name</b></Table.HeaderCell>
                                        <Table.Cell dataKey="file">{(rowData) => (<a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { attachmentService.openFile(rowData.id) }}>{rowData.file}</a>)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column flexGrow={1} minWidth={75} sortable>
                                        <Table.HeaderCell><b>Date Created</b></Table.HeaderCell>
                                        <Table.Cell dataKey="timestamp">{(rowData) => (new Date(rowData.timestamp).toLocaleDateString())}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column flexGrow={2} minWidth={125} sortable>
                                        <Table.HeaderCell><b>Type</b></Table.HeaderCell>
                                        <Table.Cell dataKey="attachmentType"></Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={100}>
                                        <Table.HeaderCell>Actions(s)</Table.HeaderCell>
                                        <Table.Cell dataKey="id">{
                                            (rowData) => (<FaIcons.FaTrash onClick={() => { deleteFile(rowData.id) }} />)
                                        }
                                        </Table.Cell>
                                    </Table.Column>
                                </Table>
                                :
                                <LoadingSpinner />
                            }
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}


export default DocumentUploadsCard;


