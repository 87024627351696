import React, { useState, useEffect } from 'react';
import { Card, Button, Tabs, Tab } from 'react-bootstrap'
import { FaEdit, FaIdCardAlt, FaPlus } from 'react-icons/fa';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import CredentialHistoryTable from '../Shared/CredentialHistoryTable';
import LegacyCredentialForm from '../Shared/Credential/LegacyCredentialForm';
import EditCredentialModal from './Credential/EditCredentialModal';
import EiCredentialForm from './Credential/EiCredentialForm';
import LsiCredentialForm from './Credential/LsiCredentialForm';
import PeCredentialForm from './Credential/PeCredentialForm';
import PlsCredentialForm from './Credential/PlsCrendentialForm';
import LegacyCredentialModal from './Credential/LegacyCredentialModal';

function CredentialCard(props) {
    const [data, setData] = useState({})
    const [selectedTab, setSelectedTab] = useState(0);
    const [legacyCredentials, setLegacyCredentials] = useState([]);
    const [modalData, setModalData] = useState();
    const [modalShow, setModalShow] = useState();
    const [legacyModalShow, setLegacyModalShow] = useState();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasPending, setHasPending] = useState(false);
    useEffect(() => {
        updateCardData();
    }, [])

    const updateCardData = () => {
        setHasLoaded(false);
        credentialService.checkHasPending(props.entityId).then((result) => {
            setHasPending(result.data);
            credentialService.getIndividualCredentialInfo(props.entityId).then((response) => {
                if (response.data.length > 0) {
                    var prefixes = response.data.map(x => x.licenseTypePrefix);
                    var legacyPrefixes = prefixes.filter(x => x == "PE-L" || x == "PLS-L") ?? [];
                    setLegacyCredentials(legacyPrefixes);
                    setData(response.data);
                    if (selectedTab == 0 && !result.data)
                        setSelectedTab(response.data[0].id);
                }
                setHasLoaded(true);
            })
        })
    }
    const onSelectTab = (selected, e) =>
    {
        if (selected == "AddButton") {
            setLegacyModalShow(true);
            e.preventDefault();
        }
        else
        {
            setSelectedTab(selected);
        }
    }
    const onEditCard = () => {
        setModalData(data.find(x => x.id == selectedTab));
        setModalShow(true);
    }
    return (
        <Card className="w-100 h-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <a href={"internal/credentialChangeLog?entityId=" + props.entityId} target="_blank" className="changeLog">
                    <span className="fakeH6">CREDENTIAL
                    </span>
                    <br />
                    <FaIdCardAlt fontSize='42' />
                </a>
            </Card.Header>
            <Card.Body>
                {hasLoaded &&
                    <LegacyCredentialModal
                        legacyCredentials={legacyCredentials}
                        show={legacyModalShow}
                        hideLegacyModal={setLegacyModalShow}
                        updateCardData={updateCardData}
                        entityId={props.entityId}
                    />
                }
                {hasLoaded && data.length > 0 ?
                    <>
                        <EditCredentialModal
                            show={modalShow}
                            onHide={() => { setModalShow(false) }}
                            credential={modalData}
                            updateCardData={updateCardData}
                            updateSupervisesCardCallback={props.updateSupervisesCardCallback}
                            updateCommentCardCallback={props.updateCommentCardCallback}
                        />
                        <Tabs transition={false} activeKey={selectedTab} onSelect={onSelectTab} className='justify-content-center'>
                            {hasPending &&
                                <Tab eventKey={0} title={"Pending"}>
                                    <br />
                                    <br />
                                    <center><h4>Application History</h4></center> <br /><CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} pendingOnly={true} />
                                </Tab>
                            }
                            {
                                data.map((item, index) => {
                                    return (<Tab eventKey={item.id} title={item.licenseTypePrefix}>
                                        <br />
                                        <br />
                                        {
                                            (item?.licenseTypePrefix == "PLS" || item?.licenseTypePrefix == "PLS-L") &&
                                            <>
                                                <PlsCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            (item?.licenseTypePrefix == "PE" || item?.licenseTypePrefix == "PE-L") &&
                                            <>
                                                <PeCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} /></>

                                        }{
                                            item?.licenseTypePrefix == "LSI" &&
                                            <>
                                                <LsiCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "EI" &&
                                            <>
                                                <EiCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "TP" &&
                                            <>
                                                <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "CPD" &&
                                            <>
                                                <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                    </Tab>)
                                })
                            }
                            {
                                legacyCredentials.length < 2 &&
                                <Tab eventKey="AddButton" title={<FaPlus />} ></Tab>
                            }
                        </Tabs>

                    </> : hasLoaded ?
                        <>
                            <center>
                                {legacyCredentials.length < 2 && <Button onClick={() => { setLegacyModalShow(true); }}><FaPlus />Add Legacy Credential</Button>}
                                <h4>Application History</h4>
                            </center> <br />
                            <CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} /></>
                        : <LoadingSpinner />

                }
            </Card.Body>
            {data.length > 0 &&
                <Card.Footer className='text-right'>
                    <Button onClick={() => onEditCard()} variant='light'><FaEdit /></Button>
                </Card.Footer>
            }
        </Card >
    )
}


export default CredentialCard;