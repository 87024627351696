import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
import { FaEdit, FaIdCardAlt, FaPlus } from 'react-icons/fa';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import CredentialHistoryTable from '../Shared/CredentialHistoryTable';
import LegacyCredentialForm from '../Shared/Credential/LegacyCredentialForm';
import EditFirmCredential from './EditFirmCredentialModal';
import FirmCredentialForm from './FirmCredentialForm';
import LegacyFirmCredentialModal from './LegacyFirmCredentialModal';

function CredentialCard(props) {
    const [data, setData] = useState({})
    const [selectedTab, setSelectedTab] = useState(0);
    const [modalData, setModalData] = useState();
    const [modalShow, setModalShow] = useState();
    const [legacyModalShow, setLegacyModalShow] = useState();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasPending, setHasPending] = useState(false);

    useEffect(() => {
        updateCardData();
    }, [])

    const updateCardData = () => {
        setHasLoaded(false);
        credentialService.checkHasPending(props.entityId).then((result) => {
            setHasPending(result.data);
            credentialService.getFirmCredentialInfo(props.entityId).then((response) => {
                if (response.data.length > 0) {
                    setData(response.data);
                    if (selectedTab == 0 && !result.data)
                        setSelectedTab(response.data[0].id);
                }
                setHasLoaded(true);
            })
        })
    }

    const onEditCard = () => {
        setModalData(data.find(x => x.id == selectedTab));
        setModalShow(true);
    }
    return (
        <Card className="w-100 h-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <a href={"internal/credentialChangeLog?entityId=" + props.entityId} target="_blank" className="changeLog">
                    <span className="fakeH6">CREDENTIAL
                    </span>
                    <br />
                    <FaIdCardAlt fontSize='42' />
                </a>
            </Card.Header>
            <Card.Body>
                {hasLoaded && data.length > 0 ?
                    <>
                        <EditFirmCredential
                            show={modalShow}
                            onHide={() => { setModalShow(false) }}
                            credential={modalData}
                            updateCardData={updateCardData}
                            updateSupervisedCardCallback={props.updateSupervisedCardCallback}
                            updateCommentCardCallback={props.updateCommentCardCallback}
                        />
                        <Tabs transition={false} activeKey={selectedTab} onSelect={setSelectedTab} className='justify-content-center'>
                            {hasPending &&
                                <Tab eventKey={0} title={"Pending"}><center><h4>Application History</h4></center> <br /><CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} pendingOnly={true} /></Tab>
                            }
                            {
                                data.map((item, index) => {
                                    return (<Tab eventKey={item.id} title={item.licenseTypePrefix}>
                                        <br />
                                        <br />
                                        {
                                            <>
                                                {
                                                    item?.licenseTypePrefix == "WG" ?
                                                        <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                        : item?.licenseTypePrefix == "CPD" ?
                                                            <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                            : <FirmCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                }
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                    </Tab>)
                                })

                            }
                        </Tabs>
                    </> : hasLoaded ? <>
                        <LegacyFirmCredentialModal
                            show={legacyModalShow}
                            hideLegacyModal={setLegacyModalShow}
                            updateCardData={updateCardData}
                            entityId={props.entityId}
                        />
                        <center>
                            {!hasPending && <Button onClick={() => { setLegacyModalShow(true); }}><FaPlus />Add WG Credential</Button>}
                            <h4>Application History</h4>
                        </center> <br />
                        <CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} />
                    </> : <LoadingSpinner />
                }
            </Card.Body>
            {data.length > 0 &&
                <Card.Footer className='text-right'>
                    <Button onClick={() => onEditCard()} variant='light'><FaEdit /></Button>
                </Card.Footer>
            }
        </Card >
    )
}


export default CredentialCard;