import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';
import Swal from 'sweetalert2';
import LegacyPeCredentialForm from './LegacyPeCredentialForm';
import LegacyPlsCredentialForm from './LegacyPlsCredentialForm';

function LegacyCredentialModal(props) {
    const [licenseType, setLicenseType] = useState([
        { value: "PLS-L", label: "PLS-L" }
        , { value: "PE-L", label: "PE-L" }].filter(license => !props.legacyCredentials.includes(license.value) ));
    const [selectedLicenseType, setSelectedLicenseType] = useState(null);
    const [credential, setCredential] = useState({
        credential: {
            applicationDate: null,
            expirationDate: null,
            effectiveDate: null,
            reinstatementDate: null,
            firstIssuanceDate: null,
            dateMod: null,
            status: null,
            statusReason: null
        }
    })
    const modalHide = () => {
        props.hideLegacyModal(false);
        setSelectedLicenseType(null);
    }
    const saveSuccess = (updatedCred) => {
        props.updateCardData();
        Swal.fire('Success!', `Legacy record has been created.`, 'success');
        props.hideLegacyModal();
    }


    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={modalHide}
        >
            <Modal.Header closeButton>
                <h4>Credential Information {selectedLicenseType != null ? "(" + selectedLicenseType + ")" : "" }</h4>
            </Modal.Header>
            <Modal.Body>
                <>
                    {selectedLicenseType == null ?
                        <>
                            <h5>Add Legacy Credential</h5>
                            <br/>
                        <SelectPicker
                            name="licenseType"
                            value={selectedLicenseType}
                            block
                            data={licenseType}
                            searchable={false}
                            placeholder="Select LicenseType"
                            onChange={setSelectedLicenseType}
                            />
                        </>
                        :
                        (selectedLicenseType == "PLS-L") ?
                            <LegacyPlsCredentialForm formId={"formModal" + selectedLicenseType} saveSuccess={saveSuccess} entityId={ props.entityId}/>
                            : (selectedLicenseType == "PE-L") ?
                                <LegacyPeCredentialForm formId={"formModal" + selectedLicenseType} saveSuccess={saveSuccess} entityId={ props.entityId} />
                                : <></>
                    }
                    {
                    }
                </>
            </Modal.Body>
            <Modal.Footer>
                <div className='w-100' >
                    <span style={{ float: 'right' }}>
                        <Button className="mr-2" variant="secondary" onClick={() => { modalHide(); }}>Close</Button>
                        {selectedLicenseType != null &&
                            <Button type="submit" form={"formModal" + selectedLicenseType} >Save</Button>
                        }
                    </span>
                </div>
            </Modal.Footer>
        </Modal >)
}

export default LegacyCredentialModal;