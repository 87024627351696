import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Checkbox, Form, FormControl, Table, TagPicker } from 'rsuite';
import Swal from 'sweetalert2';
import attachmentService from '../../../../services/Shared/attachment.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { sort_by } from '../../../Shared/HelperFunctions';

function EntityAttachments(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sortColumn, setSortColumn] = useState("file");
    const [sortType, setSortType] = useState("asc");
    const [gridData, setGridData] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [docTypes, setDocTypes] = useState([]);
    const [selectedDocTypes, setSelectedDocTypes] = useState([]);

    useEffect(() => {
        if (props.activeKey == 'attachments') {
            refreshGrid()
        }
    }, [props.activeKey])

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    const refreshGrid = () => {
        setHasLoaded(false)
        attachmentService.getFilesForEntity(props.entityId).then((data) => {
            var temp = [];
            temp = data.data.map((tempData, index) => {
                return {
                    id: tempData.id,
                    laserficheId: tempData.laserficheId,
                    parentType: tempData.parentType,
                    timestamp: tempData.uploadDate,
                    file: tempData.fileName,
                    attachmentType: tempData.documentType,
                    fileLink: ''
                };
            })
            setHasLoaded(true)
            setGridData(temp)
            setDocTypes([...new Set(temp.map(a => a.attachmentType))].map((x, i) => { return { "label": `${x}`, "value": `${x}` } }).sort(sort_by('label')));
        })
    }
    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        if (selectedDocTypes?.length > 0) {
            sortedData = sortedData.filter((v, i) => {
                return selectedDocTypes.includes(v.attachmentType)
            })
        }
        return sortedData;
    }
    const handleCheckAll = (value, checked) => {
        const checkedKeys = checked ? getData().map(item => item.id) : [];
        setCheckedRows(checkedKeys)
    }
    const handleCheck = (value, checked) => {
        if (checked)
            setCheckedRows([...checkedRows, value])
        else
            setCheckedRows(checkedRows.filter(item => item !== value));
    }
    const downloadSelected = () => {
        Swal.fire({
            title: 'Downloading Files',
            didOpen: () => {
                Swal.showLoading();
                Promise.all(checkedRows.map((val) => {
                    return attachmentService.getFileData(val)
                })).then((values) => {
                    values.forEach((val, i) => {
                        setTimeout(() => {
                            var name = new String(val.headers['content-disposition']);
                            const downloadUrl = window.URL.createObjectURL(new Blob([val.data]));
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            var fileName = name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')).replace(/^["'](.+(?=["']$))["']$/, '$1');
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }, 200 * (i + 1))
                    })
                }).then(() => {
                    Swal.close();
                });
            },
            allowOutsideClick: false
        })
    }

    const docTypeChange = (val) => {
        setSelectedDocTypes(val);
        setCheckedRows([]);
    }
    return (
        <>
            {hasLoaded ?
                <div>
                    <Row className="my-2">
                        <Col>
                            <Button disabled={checkedRows?.length == 0} onClick={downloadSelected}>Download Selected</Button>
                        </Col>
                        <Col>
                            <Form>
                                <FormControl
                                    block
                                    accepter={TagPicker}
                                    data={docTypes}
                                    name="docTypes"
                                    placeholder="Filter on Doc Type"
                                    onChange={docTypeChange}
                                />
                            </Form>
                        </Col>
                    </Row>
                    <Table
                        onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        autoHeight
                        wordWrap
                        data={getData()}>
                        <Table.Column fixed width={50}>
                            <Table.HeaderCell>
                                <Checkbox
                                    indeterminate={checkedRows?.length > 0 && checkedRows?.length != getData()?.length}
                                    onChange={handleCheckAll}
                                    checked={checkedRows?.length == getData()?.length}
                                /></Table.HeaderCell>
                            <Table.Cell dataKey="id">
                                {rowData => (
                                    <Checkbox
                                        value={rowData["id"]}
                                        onChange={handleCheck}
                                        checked={checkedRows?.some(item => item === rowData["id"])} />
                                )}

                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={125} sortable>
                            <Table.HeaderCell><b>Location</b></Table.HeaderCell>
                            <Table.Cell dataKey="parentType"></Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={2} minWidth={125} sortable>
                            <Table.HeaderCell><b>File Name</b></Table.HeaderCell>
                            <Table.Cell dataKey="file">{(rowData) => (<a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { attachmentService.openFile(rowData.id) }}>{rowData.file}</a>)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={2} minWidth={125} sortable>
                            <Table.HeaderCell><b>Doc Type</b></Table.HeaderCell>
                            <Table.Cell dataKey="attachmentType"></Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={75} sortable>
                            <Table.HeaderCell><b>Date Created</b></Table.HeaderCell>
                            <Table.Cell dataKey="timestamp">{rowData => (new Date(rowData.timestamp).toLocaleDateString())}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={50} sortable>
                            <Table.HeaderCell><b>Laserfiche ID</b></Table.HeaderCell>
                            <Table.Cell dataKey="laserficheId"></Table.Cell>
                        </Table.Column>
                    </Table>
                    <Button className="mt-2" disabled={checkedRows?.length == 0} onClick={downloadSelected}>Download Selected</Button>
                </div>
                :
                <LoadingSpinner />
            }
        </>
    )
}


export default EntityAttachments;