import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import LegacyWgCredentialForm from './LegacyWgCredentialForm';

function LegacyFirmCredentialModal(props) {
    const saveSuccess = (updatedCred) => {
        props.updateCardData();
        Swal.fire('Success!', 'Legacy WG credential has been successfully created.', 'success');
        props.hideLegacyModal();
    }

    return (<Modal
        {...props}
        onHide={props.hideLegacyModal}
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <h4>Credential Information (WG)</h4>
        </Modal.Header>
        <Modal.Body>
                <LegacyWgCredentialForm formId={"formModalWg"} saveSuccess={saveSuccess} entityId={props.entityId}/>
        </Modal.Body>
        <Modal.Footer>
            <div className='w-100' >
                <span style={{ float: 'right' }}>
                    <Button className="mr-2" variant="secondary" onClick={() => { props.hideLegacyModal(); }}>Close</Button>
                    <Button type="submit" form={"formModalWg"} >Save</Button>
                </span>
            </div>
        </Modal.Footer>
    </Modal >)
}

export default LegacyFirmCredentialModal;