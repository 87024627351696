import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, Schema, SelectPicker } from 'rsuite';
import credentialService from '../../../../../services/Internal/Credential/credential.service';
import { getCredStatus, getCredStatusReason } from '../../../../../services/Shared/codeTable.service';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function LegacyPlsCredentialForm(props) {
    const [formData, setFormData] = useState({
        entityId:props.entityId,
        formattedLicenseNumber: "",
        status: "",
        statusReason:"",
        firstIssuanceDate:null,
        effectiveDate: null,
        expirationDate: null,
        applicationDate: null,
        applicationType:"Professional Surveyor (Legacy)",
        reinstatementDate: null,
        approvedBy:"",
        userMod:"",
        dateMod:null
    });
    const [credStaus, setCredStaus] = useState([])
    const [credReason, setCredReason] = useState([])
    useEffect(() => {
        getCredStatus().then(setCredStaus);
    }, [])

    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        statusReason: ObjectType().isRequired('This field is required.'),
        status: ObjectType().isRequired('This field is required.'),

    })

    const handleCredentialStatusChange = (val) => {
        setFormData({ ...formData, status: val, statusReason: null })
        getCredStatusReason(val?.id).then(setCredReason);
    }

    const submitForm = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...formData
            }
            credentialService.createLegacyPlsCredential(dataToTransmit).then((response) => {
                props.saveSuccess(response.data);
            });
        }
    }
    return (
        <Form id={props.formId} fluid model={model} formValue={formData} onChange={setFormData} onSubmit={submitForm} >
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Credential #</b></ControlLabel>
                    <FormControl
                        name="formattedLicenseNumber"
                        
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Credential Status</b> <RedStar /></ControlLabel>
                        <FormControl
                            name="status"
                            block
                            data={credStaus}
                            accepter={SelectPicker}
                            searchable={false}
                            placeholder="Select Status"
                            onChange={handleCredentialStatusChange}
                        /> 
                </Col>
                <Col>
                    <ControlLabel><b>Credential Reason</b> <RedStar /></ControlLabel>
                        <FormControl
                            name="statusReason"
                            block
                            data={credReason}
                            accepter={SelectPicker}
                            searchable={false}
                            readOnly={!formData.status}
                            placeholder="Select Status Reason"
                        /> 
                </Col>

            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>First Issuance Date</b></ControlLabel>
                    <FormControl
                        name="firstIssuanceDate"
                        type="date"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Effective Date</b></ControlLabel>
                    <FormControl
                        name="effectiveDate"
                        type="date"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Expiration Date</b></ControlLabel>
                    <FormControl
                        name="expirationDate"
                        type="date"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Application Date</b></ControlLabel>
                    <FormControl
                        name="applicationDate"
                        type="date"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Application Type</b></ControlLabel>
                    <FormControl
                        name="applicationType"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Reinstatement Date</b></ControlLabel>
                    <FormControl
                        name="reinstatementDate"
                        type="date"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ControlLabel><b>Licensed By</b></ControlLabel>
                    <FormControl
                        name="approvedBy"
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Date of Last Activity</b></ControlLabel>
                    <FormControl
                        name="dateMod"
                        type="date"
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default LegacyPlsCredentialForm;